import React, { useEffect, useState, useCallback } from "react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { useGlobalContext } from "../Config";

const Tabla = (props) => {
  const { baseUrl } = useGlobalContext();
  const { headerText } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const { styleProperties } = useGlobalContext();

  const [desde, setDesde] = useState(1);
  const [hasta, setHasta] = useState(12);
  const [num, setNum] = useState(1);
  const [tabla, setTabla] = useState();

  const options = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
    { label: "12", value: 12 },
  ];

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    switch (name) {
      case "num":
        setNum(val);
        break;
      case "desde":
        setDesde(val);
        break;
      case "hasta":
        setHasta(val);
        break;
    }
  };

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      // Check if necessary parameters are available
      if (!num || !desde || !hasta) {
        alert(
          "Por favor, complete todos los campos requeridos antes de enviar."
        );
        return;
      }

      const endpoint = `${baseUrl}reportes/tabla?num=${encodeURIComponent(
        num
      )}&desde=${encodeURIComponent(desde)}&hasta=${encodeURIComponent(hasta)}`;
      setLoading(true);

      (async () => {
        try {
          const response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            redirect: "follow",
            headers: new Headers(),
          });

          if (!response.ok) {
            // Handle HTTP errors
            throw new Error(`Error en la solicitud: ${response.statusText}`);
          }

          const data = await response.json();

          if (!data || !data.tabla) {
            // Handle invalid or unexpected data structure
            throw new Error("Datos inválidos recibidos del servidor.");
          }

          setTabla(data.tabla);
        } catch (error) {
          // Handle any errors that occur during the fetch
          console.error("Error fetching data:", error);
          alert(
            "Ocurrió un error al obtener los datos. Por favor, inténtelo de nuevo más tarde."
          );
        } finally {
          setLoading(false); // Ensure loading state is cleared
        }
      })();
    },
    [baseUrl, num, desde, hasta, setTabla, setLoading]
  );

  const formatNumber = (value) => {
    if (value != undefined) {
      return value.toLocaleString("es-CL");
    }
    return null;
  };

  const formatCurrency = (value) => {
    if (value != undefined) {
      return value.toLocaleString("es-CL", {
        style: "currency",
        currency: "CLP",
      });
    }
    return null;
  };

  const numberTemplate = (row) => {
    return formatNumber(row.pesoNeto);
  };

  const currencyTemplate = (row) => {
    return formatCurrency(row.monto);
  };

  return (
    <div className="col-12 md:col-12">
      <h5>Tabla</h5>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="p-fluid formgrid grid">
            <div className="field col-12 md:col-1">
              <label htmlFor={num}>Numero</label>
              <InputText
                value={num}
                onChange={(e) => onInputChange(e, "num")}
              />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor={desde}>Desde</label>
              <Dropdown
                value={desde}
                onChange={(e) => onInputChange(e, "desde")}
                options={options}
                optionValue="value"
                optionLabel="label"
                placeholder="Desde"
                className="w-full md:w-14rem"
              />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor={hasta}>Hasta</label>
              <Dropdown
                value={hasta}
                onChange={(e) => onInputChange(e, "hasta")}
                options={options}
                optionValue="value"
                optionLabel="label"
                placeholder="Hasta"
                className="w-full md:w-14rem"
              />
            </div>
            <div className="field col-12 md:col-1">
              <Button
                type="submit"
                name="filterButton"
                className="login-button mb-3 px-3"
                label="Aplicar"
                style={{
                  backgroundColor: styleProperties.button.backgroundColor,
                }}
              />
            </div>
          </div>
        </form>
        <DataTable
          value={tabla}
          tableStyle={{ minWidth: "50rem" }}
          showGridlines
          emptyMessage="No se encontraron filas"
        >
          <Column
            field="operacion"
            header="Operación"
            style={{ width: "50%", textAlign: "right" }}
          />
          <Column
            field="resultado"
            header="Resultado"
            style={{ width: "50%", textAlign: "right" }}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default Tabla;
