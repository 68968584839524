import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import { useGlobalContext } from "../Config";

const Usuarios = () => {

    const { baseUrl } = useGlobalContext();
    const { headerText } = useGlobalContext();
    
    let emptyUsuario = {
        id: null,
        nombre: '',
        apellido: '',
        usuario: '',
        contrasena: '',
        perfil: {
            id: 0
        }
    };

    const [usuarios, setUsuarios] = useState(null);
    const [usuarioDialog, setUsuarioDialog] = useState(false);
    const [deleteUsuarioDialog, setDeleteUsuarioDialog] = useState(false);
    const [usuario, setUsuario] = useState(emptyUsuario);
    const [perfiles, setPerfiles] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        getUsuarios();
        getPerfiles(); 
    }, []);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    
    const getUsuarios = async () => {
        const endpoint = baseUrl + "usuarios/list";
        try {
            const data = await fetch(endpoint, {
                method: "GET",
                credentials: "include",
                redirect: "follow",
                headers: headerText,
            }).then((res) => res.json());
    
            if (data.mensaje !== "OK") {
                toast.current.show({ severity: 'error', summary: 'Error', detail: data.mensaje, life: 3000 });                
                throw new Error("Failed to fetch data");
            }
            setUsuarios(data.usuarios);
        } 
        catch (error) {
            console.error("Error", error.message);
        }
    };

    const getPerfiles = async () => {
        const endpoint = baseUrl + "perfiles/list";
        try {
            const data = await fetch(endpoint, {
                method: "GET",
                credentials: "include",
                redirect: "follow",
                headers: headerText,
            }).then((res) => res.json());
    
            if (data.mensaje !== "OK") {
                toast.current.show({ severity: 'error', summary: 'Error', detail: data.mensaje, life: 3000 });
            }
            setPerfiles(data.perfiles);
        } 
        catch (error) {
            console.error("Error", error.message);
        }
    };

    const openNew = () => {
        setUsuario(emptyUsuario);
        setSubmitted(false);
        setUsuarioDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setUsuarioDialog(false);
    };

    const hideDeleteUsuarioDialog = () => {
        setDeleteUsuarioDialog(false);
    };

    const saveUsuario = async () => {

        var endpoint = baseUrl + "usuarios/save";
        endpoint += "?nombre=" + usuario.nombre;
        endpoint += "&apellido=" +  usuario.apellido;
        endpoint += "&usuario=" + usuario.usuario;
        endpoint += "&contrasena=" + usuario.contrasena;
        endpoint += "&perfil=" + usuario.perfil.id;

        try {

            const data = await fetch(endpoint, {
                method: "GET",
                credentials: "include",
                redirect: "follow",
                headers: headerText
            }).then((res) => res.json());

            if (data.mensaje !== "OK") {
                toast.current.show({ severity: 'error', summary: 'Error', detail: data.mensaje, life: 3000 });
                throw new Error(data.mensaje);
            }
            else {
                setSubmitted(true);
                setUsuarioDialog(false);
                setUsuario(emptyUsuario);
                getUsuarios();
            }

        }
        catch (error) {
            alert(error.message);
            console.error("Error", error.message);
        }

    };

    const editUsuario = (usuario) => {
        setUsuario({ ...usuario });
        setUsuarioDialog(true);
    };

    const confirmDeleteUsuario = (usuario) => {
        setUsuario(usuario);
        setDeleteUsuarioDialog(true);
    };

    const deleteUsuario = async () => {

        var endpoint = baseUrl + "usuarios/delete?id=" + usuario.id;

        try {

            const data = await fetch(endpoint, {
                method: "GET",
                credentials: "include",
                redirect: "follow",
                headers: headerText
            }).then((res) => res.json());

            if (data.mensaje !== "OK") {
                toast.current.show({ severity: 'error', summary: 'Error', detail: data.mensaje, life: 3000 });
                throw new Error(data.mensaje);
            }
            else {
                getUsuarios();
                setDeleteUsuarioDialog(false);
                setUsuario(emptyUsuario);
                toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Usuario eliminado', life: 3000 });
            }

        }
        catch (error) {
            alert(error.message);
            console.error("Error", error.message);
        }
        
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _usuario = { ...usuario };
        _usuario[`${name}`] = val;
        setUsuario(_usuario);
    };

    const onPerfilChange = (e) => {
        let _usuario = { ...usuario };
        _usuario['perfil'].id = e.value;
        setUsuario(_usuario);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editUsuario(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mr-2" onClick={() => confirmDeleteUsuario(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Mantenedor de Usuarios</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const usuarioDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveUsuario} />
        </>
    );
    
    const deleteUsuarioDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUsuarioDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteUsuario} />
        </>
    );

    return (

        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">

                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={usuarios}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} usuarios"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron usuarios"
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column body={actionBodyTemplate} headerStyle={{ width: '112px' }}></Column>
                        <Column field="usuario" header="Usuario"></Column>
                        <Column field="nombre" header="Nombre" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="apellido" header="Apellido" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="perfil.nombre" header="Perfil" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                    </DataTable>

                    <Dialog visible={usuarioDialog} style={{ width: '450px' }} header="Usuario" modal className="p-fluid" footer={usuarioDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="nombre">Nombre</label>
                            <InputText id="nombre" value={usuario.nombre} onChange={(e) => onInputChange(e, 'nombre')} required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.nombre })} />
                            {submitted && !usuario.nombre && <small className="p-invalid">El nombre es obligatorio</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="apellido">Apellido</label>
                            <InputText id="apellido" value={usuario.apellido} onChange={(e) => onInputChange(e, 'apellido')} required className={classNames({ 'p-invalid': submitted && !usuario.apellido })} />
                            {submitted && !usuario.apellido && <small className="p-invalid">El apellido es obligatorio</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="usuario">Usuario</label>
                            <InputText id="usuario" value={usuario.usuario} onChange={(e) => onInputChange(e, 'usuario')} required className={classNames({ 'p-invalid': submitted && !usuario.usuario })} />
                            {submitted && !usuario.usuario && <small className="p-invalid">El usuario es obligatorio</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="contrasena">Contraseña</label>
                            <InputText id="contrasena" value={usuario.contrasena} onChange={(e) => onInputChange(e, 'contrasena')} className={classNames({ 'p-invalid': submitted && !usuario.contrasena })} type={showPassword ? "text" : "password"} />
                            {submitted && !usuario.contrasena && <small className="p-invalid">La contraseña es obligatoria</small>}
                        </div>
                        <div className="field">
                            <label className="mb-3">Perfil</label>
                            <Dropdown value={usuario.perfil.id} onChange={(e) => onPerfilChange(e)} options={perfiles} optionValue="id" optionLabel="nombre" placeholder="Seleccione" />
                        </div>
                    </Dialog>

                    <Dialog visible={deleteUsuarioDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteUsuarioDialogFooter} onHide={hideDeleteUsuarioDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {usuario && (
                                <span>
                                    ¿Realmente desea eliminar el usuario <b>{usuario.usuario}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                </div>
            </div>
        </div>
    );
};

export default Usuarios;
