export function obtenerPeriodoActual() {
  const fechaActual = new Date();
  const meses = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE",
  ];
  const mes = meses[fechaActual.getMonth()];
  const año = fechaActual.getFullYear().toString().slice(-2);

  return `${mes}-${año}`;
}
