import React, { useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";
import "./QrStyles.css";
import QrFrame from "../assets/qr-frame.svg";

interface QrReaderProps {
  onScanSuccess: (result: string) => void;
}

const QrReader: React.FC<QrReaderProps> = ({ onScanSuccess }) => {
  const scanner = useRef<QrScanner>();
  const videoEl = useRef<HTMLVideoElement>(null);
  const qrBoxEl = useRef<HTMLDivElement>(null);
  const [qrOn, setQrOn] = useState<boolean>(true);
  const [cameraBlocked, setCameraBlocked] = useState<boolean>(false);

  // Success
  const handleScanSuccess = (result: QrScanner.ScanResult) => {
    console.log(result);
    setQrOn(false);
    scanner.current?.stop();
    onScanSuccess(result.data);
  };

  // Fail
  const onScanFail = (err: string | Error) => {
    console.log(err);
  };

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      scanner.current = new QrScanner(videoEl?.current, handleScanSuccess, {
        onDecodeError: onScanFail,
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl?.current || undefined,
      });

      scanner?.current
        .start()
        .then(() => {
          setQrOn(true);
          setCameraBlocked(false);
        })
        .catch((err) => {
          if (err) {
            setQrOn(false);
            setCameraBlocked(true);
          }
        });
    }

    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (!qrOn && cameraBlocked)
      alert(
        "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
      );
  }, [qrOn, cameraBlocked]);

  return (
    <div className="qr-reader">
      <video ref={videoEl}></video>
      <div ref={qrBoxEl} className="qr-box">
        <img
          src={QrFrame}
          alt="Qr Frame"
          width={256}
          height={256}
          className="qr-frame"
        />
      </div>
    </div>
  );
};

export default QrReader;
